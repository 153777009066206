import React from 'react'
import styled, { injectGlobal } from 'styled-components'

injectGlobal`
  @import url('https://fonts.googleapis.com/css?family=Rancho');

  *,
  *::after,
  *::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
  }
  
  body {
    box-sizing: border-box;
  }
`

const Page = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #333;
  width: 100vw;
  height: 100vh;
`

const Inner = styled.div`
  font-family: 'Rancho', monospace;
  font-size: 5em;
  color: #cb6015;
  text-align: center;
`

export default () => (
  <Page>
    <Inner>
      Hover Design
    </Inner>
  </Page>
)
